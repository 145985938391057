import React from 'react'
import { Link } from 'react-scroll'
import logo from "../assets/logo.png"

export default function HeroLogo() {
    return (
        <Link
            to="app"
            spy={true}
            smooth={true}
            duration={500}
            className="flex items-center no-underline hover:text-slate-600 hover:scale-105 hover:cursor-pointer active:opacity-80 active:scale-90 gap-2"
        >
            {/* <img src={logo} alt="logo" className="w-12" /> */}
            <span className="text-5xl font-bold">HV</span>
        </Link>
    )
}
