import React from 'react'
import logo from '../assets/logo.png'
import ScrollButton from './ScrollButton'

export default function Hero() {
  return (
    <div id="hero" className="flex flex-col section-min-height sm:scroll-mt-20 justify-center">
        <div className='flex flex-col-reverse mx-5 lg:flex-row sm:ml-20 md:mx-auto'>
            <div className='flex flex-col sm:w-[50%] mx-auto justify-center items-center sm:gap-5 sm:mt-0'>
                <div>
                    <h1 className='text-6xl sm:text-8xl lg:text-9xl font-bold text-center'>Under construction </h1>
                    {/* <h1 className='text-6xl sm:text-8xl lg:text-9xl font-bold text-center lg:text-left'>Under construction </h1> */}
                    {/* <h2 className='text-xl md:text-3xl'>stravování na pár kliknutí</h2> */}
                </div>
                <div>
                    <h2 className='animate-spinning text-6xl'>🛠️</h2>
                </div>
                {/* <div className='flex flex-col my-4 sm:flex-row gap-3 lg:w-[80%]'>
                <ScrollButton destination="download" text="Stáhnout aplikaci" color="bg-orange-400" />
                <ScrollButton destination="app-detail" text="Více informací" color="bg-cyan-700" />
                </div> */}
            </div>
            {/* <div className='mx-auto w-full md:w-[80%] lg:w-[50%]'>
                <img src={logo} alt="logo" />
            </div> */}
        </div>
    </div>
  )
}
