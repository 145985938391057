import { useState } from "react";
import MenuItem from "./MenuItem";
import Hamburger from "./Hamburger";
import HeroLogo from "./HeroLogo";

export default function Header() {

    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    return (
        <header className="border-b-2 border-slate-900 border-solid sticky shadow-md top-0 bg-white z-10">
            <div className="flex justify-between items-center my-0 mx-auto max-w-5xl py-5 px-12">
                <HeroLogo />
                <Hamburger
                    menuOpen={menuOpen}
                    toggleMenu={toggleMenu}
                />

                <nav className="gap-5 hidden md:flex">

                    <MenuItem
                        destination="about"
                        text="O MNĚ"
                    />
                    <MenuItem
                        destination="work"
                        text="PORTFOLIO"
                    />
                    <MenuItem
                        destination="contact"
                        text="KONTAKT"
                    />
                </nav>
            </div>
            <div id="mobile-menu" className={`${menuOpen ? "flex" : "hidden"} gap-5 flex flex-col md:hidden bg-slate-100 animate-open-menu origin-top text-center p-5 text-2xl`} >
                <MenuItem
                    destination="about"
                    text="O MNĚ"
                    offset={-300}
                    onClick={toggleMenu}
                />

                <MenuItem
                    destination="work"
                    text="PORTFOLIO"
                    offset={-300}
                    onClick={toggleMenu}
                />
                <MenuItem
                    destination="contact"
                    text="KONTAKT"
                    offset={-300}
                    onClick={toggleMenu}
                />
            </div>
        </header>
    )
} 