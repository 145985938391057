import logo from "../assets/logo.png"
import HeroLogo from "./HeroLogo";

import { Link } from "react-scroll";

export default function Footer() {
    return (
        <footer className="border-t-2 border-solid border-slate-900 mt-auto bg-white">
            <div className="flex justify-between py-5 px-12 items-center my-0 mx-auto max-w-5xl">
                <p className="">
                    All Rights Reserved
                </p>
                <HeroLogo />
                <p className="text-end">
                    <span className="hidden md:inline">Copyright</span> ©2024 Jan Vlček
                </p>
            </div>
        </footer>
    )
} 