import React from 'react'
import { Link } from 'react-scroll'

export default function MenuItem({destination, text, offset, onClick}) {
    return (
        <Link
            to={destination}
            spy={true}
            smooth={true}
            duration={500}
            offset={!offset ? -80 : offset}
            className="no-underline hover:text-slate-600 hover:scale-105 hover:cursor-pointer active:opacity-80 active:scale-90 text-xl"
            onClick={onClick && onClick}
        >
            {text}
        </Link>
    )
}
