import React from 'react'
import About from './components/About'
import Hero from './components/Hero'
import Header from './components/Header'
import Footer from './components/Footer'

export default function App() {
  return (
    <div id="app" className='bg-gray-50 text-lg min-h-screen font-nunito text-blue-gray-900'>
      <Header />
      <Hero />
      {/* <main className="max-w-4xl my-0 mx-auto">
        <About />
      </main> */}

      <Footer />
    </div>
  )
}
